import React from 'react'
import InterestCalculatorForm from '../../components/InterestCalculator/InterestCalculator'
import Layout from '../../components/Layout'
import { Seo } from '../../components/seo'

export default function InterestCalculator() {
    return (
      <>
        <Seo
          title='Interest Calculator'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <InterestCalculatorForm />
        </Layout>
      </>
    );
}

import React, { useState, useEffect } from 'react';
import FAQAccord from '../GFlush';
import ContentImg from '../GSTTemp/ContentImg';

export default function InterestCalculatorForm() {
  /* Interest Slider */
  // const InterestData = {
  //   header: `Simple Interest Calculator`,
  //   sliders: [
  //     {
  //       label: `Principal amount `,
  //       name: `principal_amount`,
  //       className: `principal_amount`,
  //       id: `slider1`,
  //       min: `0`,
  //       max: `5`,
  //       sliders_name: `principal_slider`,
  //       slider_id: `slider1 customRange2 `,
  //     },
  //     {
  //       label: `Rate of interest`,
  //       name: `interest`,
  //       className: `interest`,
  //       id: `slider2`,
  //       min: `0`,
  //       max: `5`,
  //       sliders_name: `interest_slider`,
  //       slider_id: `slider2 customRange2 `,
  //     },
  //   ],
  // };
  /*  Content Img Component Data */
  const rentCiData = {
    id: 'interest-calculator',
    background: '',
    mt_div: '',
    mt_img: '',
    header: 'Interest Calculator',
    paragraph: ``,
    points: [
      {
        head: ``,
        content: `You either have to pay some interest when making investments 
        or taking out loans, or you get interest on your investments.`,
        icon: true,
      },
      {
        head: ``,
        content: `Planning your finances requires calculating the amount you must
         pay or will receive after a specific amount of time.You can use our 
         user-friendly basic interest calculator for this purpose.`,
        icon: true,
      },
      {
        head: `What is Simple Interest?`,
        content: `On money that has been lent, borrowed, or invested, interest is 
        a fee. A type of interest calculation known as simple interest does not account
         for many periods of interest payments or charges.`,
        icon: true,
      },
      {
        content: `In other words, the interest rate will not be affected by any 
        accumulated interest and will only be applicable to the loan or investment's principal amount.`,
        icon: true,
      },
      {
        head: ``,
        content: `Kind of payment`,
        icon: true,
      },
      {
        content: `You are now prepared with the needed TDS deduction. `,
        icon: true,
      },
    ],
    image: '/imgs/registration/tax/tax-img-slider.png',
    alt_tag: 'Interest Calculator',
  };
  /* FaQ data */
  var RentGenFAQ = [
    {
      header: 'What is an interest calculator?',
      body: [
        {
          content: `To figure out the interest on loans and savings accounts without
           compounding, a simple interest calculator is a useful tool. Simple interest
            on the principal amount can be computed on a daily, monthly, or annual basis.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header:
        'What advantages does using the TODAYFILINGS easy interest calculator offer?',
      body: [
        {
          content: `You may calculate how much interest you must pay on the loans you
           have taken out and how much you must set aside each month to reach your goal 
           using this straightforward interest calculator. Also, you may quickly determine
            how much interest you will earn on your deposits.`,
          icon: false,
        },
      ],
    },
    {
      header: `Qualifications for Using an Interest Calculator?`,
      body: [
        {
          content: `This calculator is available to everyone and doesn't require any
           login information or papers to use.`,
          icon: true,
        },
      ],
    },
  ];

  const [slideData, setSlideData] = useState({
    principal_amount: '0',
    interest_amount: '1',
    period: '',
    duration: '',
  });
  const [finalData, setFinalData] = useState({
    principal_amount: '0',
    total_value: '0',
    total_interest: '',
  });

  useEffect(() => {
    let principal_amount = parseInt(slideData.principal_amount);
    let interest_rate = parseFloat(slideData.interest_amount).toFixed(2);
    let period_unit = slideData.period;
    let duration = parseFloat(slideData.duration);
    let total_value = principal_amount;
    let total_interest = 0;
    // let graph_data = '100 0';
    if (
      principal_amount &&
      interest_rate &&
      duration &&
      period_unit.length > 0
    ) {
      let pr_interest = 0;
      interest_rate = parseFloat(interest_rate / 100);
      switch (period_unit) {
        case 'day':
          pr_interest = interest_rate / 365;
          break;
        case 'week':
          pr_interest = interest_rate / 52;
          break;
        case 'month':
          pr_interest = interest_rate / 12;
          break;
        case 'quarter':
          pr_interest = interest_rate / 4;
          break;
        case 'year':
          pr_interest = interest_rate;
          break;
        default:
          pr_interest = 0;
          break;
      }
      total_interest = principal_amount * pr_interest * duration;

      total_value += total_interest;
      // var graph_param1 = parseInt((principal_amount * 100) / total_value);
      // graph_data = graph_param1 + ' ' + (100 - graph_param1);
    }
    setFinalData({
      principal_amount: principal_amount,
      total_value: total_value.toFixed(2),
      total_interest: total_interest.toFixed(2),
    });
  }, [slideData]);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setSlideData({ ...slideData, [name]: value });
  };

  return (
    <>
      <section className='interest-calculator mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='col-md-12 mt-5'>
                <h4 className='section-header'>
                  Simple Interest Calculator<div className='bar'></div>
                  <hr />
                </h4>
              </div>
              <div className='row card'>
                <div className='row box'>
                  <div className='col-md-7 lable-wrapper d-flex align-items-center'>
                    <label htmlFor='slider1_amount'>Principal amount </label>
                  </div>
                  <div className='col-md-5 range-output text-end'>
                    <i className='fa fa-inr' aria-hidden='true'></i>
                    <input
                      type='number'
                      name='principal_amount'
                      className='principal_amount'
                      id='slider1'
                      onChange={handleInput}
                      value={slideData.principal_amount}
                    />
                  </div>
                  <input
                    value={slideData.principal_amount}
                    onChange={handleInput}
                    type='range'
                    className='form-range principal_slider'
                    min='0'
                    max='10000'
                    id='customRange2'
                    name='principal_amount'
                  ></input>
                </div>
                <div className='row box'>
                  <div className='col-md-7 lable-wrapper d-flex align-items-center'>
                    <label htmlFor='slider1_amount '>Rate of Interest</label>
                  </div>
                  <div className='col-md-5 range-output text-end'>
                    <i className='fa fa-inr' aria-hidden='true'></i>
                    <input
                      type='number'
                      name='interest_amount'
                      className='interest_amount'
                      id='slider1'
                      onChange={handleInput}
                      value={slideData.interest_amount}
                    />
                  </div>
                  <input
                    value={slideData.interest_amount}
                    onChange={handleInput}
                    type='range'
                    className='form-range principal_slider'
                    min='1%'
                    max='50%'
                    id='customRange2'
                    name='interest_amount'
                  ></input>
                </div>
                <div className='row d-flex mt-2'>
                  <div className='col-md-6  '>
                    <label htmlFor='PAN of Recipient' className='form-label'>
                      Period Unit
                    </label>
                  </div>
                  <div className='col-md-6  '>
                    <select
                      name='period'
                      className='form-select'
                      aria-label='Default select example'
                      value={slideData.period}
                      onChange={handleInput}
                    >
                      <option value=''>Select</option>
                      <option value='day'>Day</option>
                      <option value='week'>Week</option>
                      <option value='month'>Month</option>
                      <option value='quarter'>Quarter</option>
                      <option value='year'>Year</option>
                    </select>
                  </div>
                </div>
                <div className='row d-flex mt-4'>
                  <div className='col-md-6 mb-3'>
                    <label htmlFor='Duration of Payment' className='form-label'>
                      Duration
                    </label>
                  </div>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='duration form-control'
                      id='duration'
                      name='duration'
                      required='required'
                      autoComplete='off'
                      placeholder='0'
                      value={slideData.duration}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'></div>
            <div className='col-md-3 interest-results'>
              <h4 className='section-header mb-5'>
                Results<div className='bar'></div>
                <hr />
              </h4>
              <div className='mt-5'>
                <div className='row d-flex mt-5 box'>
                  <div className='col-md-7 '>
                  <p className='principal'>Principal Amount</p>
                </div>
                  <div className='col-md-5 '>
                    <p className='principal_result float-end'>
                      <strong>₹ {finalData.principal_amount}</strong>
                    </p>
                </div>
              </div>
                <div className='row d-flex mt-5 box'>
                <div className='col-md-6 '>
                  <p className='interest'>Total Interest</p>
                </div>
                <div className='col-md-6'>
                    <p className='total_interest float-end'>
                      <strong>₹ {finalData.total_interest}</strong>
                    </p>
                </div>
              </div>
                <div className='row d-flex mt-5 box'>
                <div className='col-md-6 '>
                  <p className='total'>Total Value</p>
                </div>
                <div className='col-md-6'>
                    <p className='total float-end'>
                      <strong>₹ {finalData.total_value}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContentImg CISection_data={rentCiData} />
      <FAQAccord items={RentGenFAQ} />
    </>
  );
}
